<!-- vue coding=utf-8 -->
<!--
 * @Author: Chen-
 * @Description: 修改登录密码
 * @Date: 2020-12-27 19:48:39
 * @LastEditors: OBKoro1
 * @LastEditTime: 2021-02-19 12:18:47
 * @FilePath: /yue_quanzhan_h5_new/src/views/user/member/Cloginpas.vue
-->
<template>
  <div class="user_content">
    <van-form class="userCloginpas">
      <van-field
        v-model="mobile"
        label="+86"
        placeholder="请输入新的手机号码"
      />
      <van-field v-model="code" label="验证码" placeholder="请输入验证码">
        <template #button>
          <van-button
            size="small"
            plain
            type="warning"
            :disabled="isCodeBtn"
            @click.stop="getCode()"
          >{{ innerText }}
          </van-button>
        </template>
      </van-field>
      <van-field v-model="password" label="新密码" placeholder="请输入新密码" />
      <div style="margin: 16px;">
        <div class="btn" @click="onSubmit">修改密码</div>
      </div>
    </van-form>
    <div id="c1" />
    <!-- <div class="brew_box" v-if="isDingxiang"></div> -->
  </div>
</template>

<script>
import Vue from 'vue'
import { Button, Field, Form, Toast } from 'vant'

Vue.use(Form).use(Field).use(Toast).use(Button)
import {
  authnewSend,
  isCheckUserRegister,
  updatePassword, upnickname
} from '@/services/userApi'

export default {
  data() {
    return {
      mobile: '',
      code: '',
      password: '',
      innerText: '获取验证码',
      wait: 59, // 倒计时
      isCodeBtn: false,
      isDingxiang: false, // 顶象模拟蒙层
      myCaptcha: '', // 顶象验证
      myCaptchaToken: '', // 滑块token
      code_num: '', // 邀请码
      areaCode: '86' // 区域码
    }
  },
  created() {
    const _self = this
    _self.mobile = this.$route.query.mobile
  },
  mounted() {
    const _self = this
    // eslint-disable-next-line no-undef
    _self.myCaptcha = _dx.Captcha(document.getElementById('c1'), {
      appId: '6738137ae0a10824eb3b5f775a1bf7fb',
      success: function(token) {
        _self.myCaptchaToken = token
        _self.lsendNewByWeChat()
        _self.myCaptcha.hide()
        _self.isDingxiang = false
      },
      fail: function(err) {
        console.log(err)
      },
      language: localStorage.getItem('language'),
      style: 'popup',
      inlineFloatPosition: 'up'
    })
  },
  methods: {
    onSubmit() {
      const parm = {
        mobile: this.mobile,
        code: this.code,
        password: this.password
      }
      updatePassword(parm).then((res) => {
        if (Number(res.code) === 200) {
          Toast(res.msg)
          this.$router.push('/perinfo')
        } else {
          Toast(res.msg)
        }
      })
    },
    getCode() {
      const _self = this
      window.scroll(0, 0)
      const _phone = _self.mobile
      const phoneReg = /^1\d{10}$/
      if (!_phone || !phoneReg.test(_phone)) {
        Toast('请输入有效的手机号码！')
        return false
      } else {
        _self.lisCheckUserRegister()
      }
    },
    sTime() {
      const _self = this
      if (_self.wait === 0) {
        _self.innerText = '获取验证码'
        _self.myCaptchaToken = ''
        _self.myCaptcha.reload()
        _self.wait = 59
        _self.isCodeBtn = false
      } else {
        _self.innerText = _self.wait + 's后再发'
        _self.isCodeBtn = true
        _self.wait--
        setTimeout(function() {
          _self.sTime()
        }, 1000)
      }
    },
    lisCheckUserRegister() {
      const _self = this
      const params = {
        mobile: _self.mobile
      }
      isCheckUserRegister(params).then((res) => {
        if (Number(res.code) === 200) {
          _self.wait = 59
          _self.myCaptcha.show()
          _self.isDingxiang = true
        }
      })
    },
    lsendNewByWeChat() {
      const _self = this
      const params = {
        mobile: _self.mobile
      }
      authnewSend(params).then((res) => {
        if (Number(res.code) === 200) {
          Toast('验证发送成功')
          _self.sTime()
        } else {
          Toast(res.data.messages)
          this.$router.push('/perinfo')
        }
      })
    },
    upname() {
      if (this.name === '') {
        return Toast('不能为空')
      }
      const parm = {
        name: this.name
      }
      upnickname(parm).then((res) => {
        if (Number(res.code) === 200) {
          Toast(res.msg)
          this.$router.push({ path: '/perinfo' })
        } else {
          Toast(res.msg)
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
html,
body,
#app {
  height: 100%;
  background: #f6f6f6 !important;
  overflow: hidden;
}

.user_content {
  width: 100%;
  height: 100%;
  // overflow: auto;
  // position: fixed;
  // top: 0;
  // left: 0;
  // right: 0;
  // bottom: 0;
  background: #f6f6f6;
  // padding-bottom: 80px;
  .tip {
    padding: 13px;
    color: #999999;
    font-size: 12px;
  }

  .btn {
    background: #e33f44;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
  }
}

.brew_box {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(0, 0, 0);
  opacity: 0.5;
  z-index: 10;
}

.userCloginpas {
  .van-cell {
    /deep/ .van-field__label {
      width: 60px;
    }
  }
}
</style>
